export enum EffectToInit {
  /**
   * creating userSearch into a system search
   * update a userSearch or system search.
   */
  SEARCH_QUERY_MANAGEMENT = 'SEARCH_QUERY_MANAGEMENT',

  /**
   * showing favorite and recent search to user.
   * marking a search as favorite.
   */
  USER_SEARCH_FAVORITES_AND_RECENT_MANAGEMENT = 'USER_SEARCH_FAVORITES_AND_RECENT_MANAGEMENT',

  /**
   * load slot based on the system search
   */
  LOADING_SLOTS_BASED_ON_SYSTEM_SEARCH = 'LOADING_SLOTS_BASED_ON_SYSTEM_SEARCH',

  /**
   * selecting slots
   * managing the views.
   * managing timeRange in view
   */
  SCHEDULE_MANAGEMENT = 'SCHEDULE_MANAGEMENT',

  /**
   * list of locations and statistics about it based on search query.
   */
  SEARCH_QUERY_LOCATION_STATISTICS = 'SEARCH_QUERY_LOCATION_STATISTICS',

  /**
   * loading a list of matching candidates
   * assigning candidate
   */
  CANDIDATE_ASSIGN = 'CANDIDATE_ASSIGN',

  /**
   * Mode in which the slot checkboxes are available and we can select slots.
   * This mode enables the user to minipulate the list of selected slots but isn't
   * about the list. for that see {@see SELECTED_SLOTS_MANAGEMENT }
   */
  SELECTING_SLOTS = 'SELECTING_SLOTS',

  /**
   * This progress keeps the list of selected slot which will be cleared when the progress
   * is being stopped
   */
  SELECTED_SLOTS_MANAGEMENT = 'SELECTED_SLOTS_MANAGEMENT',

  /**
   * Create system searches based on the selected location and product(s).
   */
  CREATE_SEARCH_QUERY_FOR_LOCATION_SCHEDULE = 'CREATE_SEARCH_QUERY_FOR_LOCATION_SCHEDULE',

  /**
   * Create system searches based on the view input in the search view.
   */
  CREATE_SEARCH_QUERY_FOR_SEARCH_VIEW = 'CREATE_SEARCH_QUERY_FOR_SEARCH_VIEW',

  /**
   * Products for the available schedules at the location schedule overview.
   */
  PRODUCT_SCHEDULE_MANAGEMENT = 'PRODUCT_SCHEDULE_MANAGEMENT',

  /**
   * This is to process in which we load detailed information about a selected candidate.
   */
  CANDIDATE_DETAILS_MANAGEMENT = 'CANDIDATE_DETAILS_MANAGEMENT',

  /**
   * Manage the lists of approaches. This will fetch the candidates and all relevant approaches.
   */
  MANAGE_APPROACH_LISTS = 'MANAGE_APPROACH_LISTS',

  /**
   * Fetching the best matching slots for a candidate. Used at the approach flow.
   */
  FETCHING_BEST_MATCHING_SLOTS_FOR_CANDIDATE = 'FETCHING_BEST_MATCHING_SLOTS_FOR_CANDIDATE',

  /**
   * Manage the active approach. The user can select slots to approach the candidate for.
   */
  CREATING_APPROACH_TO_SEND_TO_CANDIDATE = 'CREATING_APPROACH_TO_SEND_TO_CANDIDATE',

  /**
   * Manage the active approach. The user can specify for each slot (assignment)
   * if the candidate should be planned or rejected.
   */
  HANDLE_CANDIDATE_REACTING_TO_APPROACH = 'HANDLE_CANDIDATE_REACTING_TO_APPROACH',

  /**
   * This process gathers information to supply the user with alternative slot to approach the candidate
   * with when the original assignment is already been filled
   */
  SHOW_ALTERNATIVE_SHIFTS = 'SHOW_ALTERNATIVE_SHIFTS',

  /**
   * This enables assignment card to show inline assign buttons
   */
  SHOW_INLINE_ASSIGN_BUTTONS = 'SHOW_INLINE_ASSIGN_BUTTONS',

  /**
   * This enables assignment card to show clearly that it cannot be assigned because it's already assigned.
   * Mainly used in the Approach candidate flow
   */
  SHOW_ASSIGNMENT_AS_NOT_AVAILABLE_ASSIGNING = 'SHOW_ASSIGNMENT_AS_NOT_AVAILABLE_ASSIGNING',
}
